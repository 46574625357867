<template>
    <div class="base">
        <div class="base-main">
            <div class="base-first">
                <div class="base-first-box">
                    <div class="base-contactUs">
                        <i class="el-icon-dianhua1"></i>
                        <span>联系我们</span>
                        <i v-show="show" @click="showClick" class="el-icon-31shouqi"></i>
                        <i v-show="!show" @click="showClick" class="el-icon-xiangxiazhankai"></i>
                    </div>
                    <div class="base-phone" v-show="show">
                        <div class="league" @click="leagueClick">品牌加盟</div>
                        <div class="phone">客服电话：0571-82573193 15336976337</div>
                    </div>
                    <div class="base-contactUss">
                        <i class="el-icon-dizhi"></i>
                        <span>联系我们</span>
                        <span class="base-address-text">浙江省 杭州市 萧山区 建设三路40号杭州珠宝批发城A103帕马仕钻石</span>
                    </div>
                </div>
                <div class="base-code-box">
                    <div class="code-one">
                        <div class="code-img">
                            <img src="../assets/img/kefu.jpg" alt="">
                        </div>
                        <div class="code-text">联系客服</div>
                    </div>
                    <div class="code-Two">
                        <div class="code-img">
                            <img src="../assets/img/pazuan.jpg" alt="">
                        </div>
                        <div class="code-text">下载葩钻APP</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="base-text">
            <span>浙ICP备2021037216号-2 杭州甄钻珠宝首饰有限公司</span>
        </div>
        <el-dialog :visible.sync="messageFlag" width="30%" center :before-close="handleClose" :close-on-click-modal="false">
            <div class="header-title" slot='title'>留言给我们</div>
            <el-form :model="formData" :rules="rules" ref="formData" label-width="60px" class="demo-ruleForm">
                <el-form-item label="姓名" prop="leavName">
                    <el-input v-model="formData.leavName"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="leavPhone">
                    <el-input v-model="formData.leavPhone"></el-input>
                </el-form-item>
                <el-form-item label="留言" prop="leavContent">
                    <el-input type="textarea" class="leav-textarea" resize="none" v-model="formData.leavContent"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button class="footer-button" @click="submitForm('formData')">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import { AddLeavingInfo } from "../api/home.js";
    export default {
        data(){
            let validatePhone = (rule, value, callback) => {
                if (value === '') {
                callback(new Error('请输入电话'));
                } else {
                    var text = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                    if(!text.test(value)){
                        callback(new Error('电话号码格式不正确!'));
                    }else{
                        callback();
                    }
                }
            };
            return{
                show:false,
                messageFlag:false,
                formData:{
                    leavName:'',
                    leavPhone:'',
                    leavContent:'',
                    createTime:''
                },
                rules:{
                    leavName:[
                        { required: true, message: '请输入姓名', trigger: 'blur' },
                    ],
                    leavPhone:[
                        { required: true, validator:validatePhone, trigger: 'blur' },
                    ],
                    leavContent:[
                        { required: true, message: '请输入姓名', trigger: 'blur' },
                    ],
                }
            }
        },
        methods:{
            showClick(){
                this.show = !this.show
            },
            leagueClick(){
                this.messageFlag = true
            },
            handleClose(){
                this.$refs['formData'].resetFields();
                this.messageFlag = false
            },
             submitForm(formName) {
                this.formData.createTime = new Date()
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.AddLeav()
                    } else {
                        return false;
                    }
                });
            },
            async AddLeav(){
                let params = this.formData
                const res = await AddLeavingInfo(params)
                if(res.Status == '200'){
                    this.$message.success('留言成功')
                    this.handleClose()
                }else{
                    this.$message.success(res.Message)
                }
            },
        },
    }
</script>
<style scoped lang="scss">
    .base{
        width: 100%;
        height: 215px;
        background: #1717B6;
        margin-top: 150px;
        &-main{
            width: 1200px;
            margin: 0 auto;
            height: 175px;
        }
        &-code-box{
            width: 50%;
            display: flex;
            justify-content: flex-end;
        }
        .footer-button{
            width: 520px;
            height: 50px;
            background: #1717B6;
            border-radius: 4px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }
        /deep/.el-dialog{
            border-radius: 20px;
        }
        .leav-textarea{
            /deep/ .el-textarea__inner{
                height: 240px;
            }
        }
        .header-title{
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #333333;
        }
        .code-one{
            padding: 10px 0;
            width: 120px;
        }
        .code-Two{
            padding: 10px 0;
            width: 120px;
            margin-left: 20px;
        }
        .code-img{
            width: 120px;
            height: 120px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .code-text{
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            text-align: center;
            margin-top: 5px;
        }
        &-phone{
            padding: 0 40px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #D6D6D6;
            .phone{
                margin-top: 10px;
            }
            .league{
                cursor: pointer;
            }
        }
        &-address-text{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            margin-left: 10px;
        }
        &-contactUs{
            color: #fff;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            display: flex;
            align-items: center;
            padding: 20px 0 10px 0;
            .el-icon-dianhua1,.el-icon-dizhi{
                font-size: 32px;
                margin-right: 10px;
            }
            .el-icon-31shouqi,.el-icon-xiangxiazhankai{
                font-size: 16px;
                cursor: pointer;
                margin-left: 10px;
            }
        }
        &-contactUss{
            color: #fff;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            display: flex;
            align-items: center;
            padding: 10px 0 10px 0;
            .el-icon-dianhua1,.el-icon-dizhi{
                font-size: 32px;
                margin-right: 10px;
            }
            .el-icon-31shouqi,.el-icon-xiangxiazhankai{
                font-size: 16px;
                cursor: pointer;
                margin-left: 10px;
            }
        }
        &-first{
            width: 1200px;
            margin: 0 auto;
            display: flex;
        }
        &-first-box{
            width: 50%;
        }
        &-text{
            height: 40px;
            border-top: 1px rgba(255,255,255,0.5) solid;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #E2E2E2;
        }
        
    }
</style>